.override {
  :global {
    .adm-space {
      margin: 0 -4px;
    }
    .adm-space-item {
      width: calc(25% - 8px);
      margin: 0 4px;
    }

    .adm-space-horizontal.adm-space-wrap > .adm-space-item {
      padding-bottom: 8px;
    }

    .adm-selector-item {
      width: 100%;
    }
  }
}

.container {
  :global {
    .adm-dialog-content {
      padding: 0 0 12px 0;
    }
  }
}
